.App {
    width: 95vw;
    height: 75vh;
    background: linear-gradient(to bottom, #31373E, #11151A);
    border: 1px solid rgba(white, .3);
    border-radius: 20px;
    box-shadow: -120px 100px 70px rgba(#55575E, .65);
    padding: 20px;
    .app-container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr 2fr;
        gap: 20px;
        .app-block {
            background-color: #3c3f448e;
            border: 1px solid rgba(white, .05);
            border-radius: 20px;
            overflow: hidden;
            padding: 20px;
        }
        .block1 {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
            .block1__user-conteiner__connection {
                .connected {
                    color: green;
                }
                .disconnected {
                    color: red;
                }
            }
            .block1__user-conteiner__user {
                margin-top: 20px;
                display: flex;
                align-items: center;
                .user-avatar img {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    object-fit: cover;
                }
                .user-name {
                    margin-left: 20px;
                    font-size: 20px;
                }
            }
            .block1__telegram {
                display: flex;
                align-items: center;
                padding: 20px;
                img {
                    width: 30px;
                    height: 30px;
                }
                a {
                    margin-left: 15px;
                    font-size: 15px;
                    color: #DAA320;
                }
            }
        }
        .block2 {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;
            overflow-y: auto;
            .block2__info {
                width: 50%;
                max-height: 50%;
                margin-top: 10px;
                margin-bottom: 20px;
                .title {
                    font-size: 20px;
                    margin-bottom: 20px;
                    color: #DAA320;
                }
                .price {
                    font-size: 17px;
                    margin-bottom: 10px;
                }
                .change {
                    font-size: 17px;
                }
            }
            .block2__update {
                border-top: 1px solid #DAA320;
                padding-top: 20px;
                & > p {
                    font-size: 20px;
                }
                .block2__update__coins {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 20px;
                    width: 50px;
                    height: 50px;
                    border: 4px solid #DAA320;
                    border-radius: 50%;
                    cursor: pointer;
                    & > p {
                        padding: 20px;
                        font-size: 17px;
                    }
                }
            }
        }
        .block3 {
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 3;
            .block3__title {
                display: flex;
                justify-content: space-between;
                padding: 10px 10px 10px 0px;
                font-size: 20px;
                .coin {
                    color: #DAA320;
                }
            }
            .block3__coins-list {
                width: 100%;
                height: 100%;
                overflow: auto;
                padding-bottom: 30px;
                padding-right: 5px;
            }
            
        }
        .block4 {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 3;
            .block4__chart{
                height: 100%;
            }
        }
        
    }
}
.auth-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15vw;
    height: 10vh;
    background-color: #11151A;
    border: 1px solid rgba(white, .3);
    border-radius: 20px;
    box-shadow: -30px 25px 35px rgba(#55575E, .65);
    color: #EAEBED;
    cursor: pointer;
    transition: all 1s ease .1s;
    p {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 2px;
    }
}
.auth-container:hover {
    background-color: #daa220ea;
    color: #11151A;
}

@media (max-width: 700px) {
    .App {
        overflow: auto;
        .app-container {
            display: flex;
            flex-direction: column;
            .app-block {
                min-height: 300px;
            }
            
        }
    }
}

@media (max-width: 750px) {
    .auth-container {
        width: 35vw;
        height: 5vh;
    }
}
