.placeholder {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: #3c3f448e;
    position: relative; // 
    overflow-x: hidden; //
    .flare {
        position: absolute;
        top: 0;
        height: 100%;
        width: 70px;
        animation: flareAnimation;
        transform: skewX(-3deg);
        left: -110%;
        background: linear-gradient(90deg, rgba(#3c3f44, 0.1), rgba(255, 255, 255, 0.1));
        animation: flareAnimation 1.6s infinite linear; 
    }
}

@keyframes flareAnimation {
    0% {
        left: -110%;
    }
    100% {
        left: 110%;
    }
}