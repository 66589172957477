* {
	padding: 0;
	margin: 0;
	border: 0;
}

/* Scroll */
* {
	scrollbar-width: thin;
	scrollbar-color: #DAA320;
}
  
/* Scroll for Chrome, Edge, Safari */
*::-webkit-scrollbar {
width: 3px;
}

*::-webkit-scrollbar-track {
background: rgba(255, 255, 255, 0.301);
}

*::-webkit-scrollbar-thumb {
background-color: #DAA320;
border-radius: 20px;
}

/* ---------*/

*,*:before,*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	background-origin: border-box;
}

:focus, :active {overflow: none;}
a:focus, a:active {overflow: none;}

nav, footer, header, aside {display: block;}

html, body {
	height: 100%;
	width: 100%;
	font-size: 100%;
	line-height: 1;
	font-size: 14px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

input, button, textarea {
	font-family: inherit;
}

input::-ms-clear {display: none;}
button {cursor: pointer;}
button::-moz-focus-inner {padding: 0; border: 0;}
a, a:visited {text-decoration: none;}
a:hover {text-decoration: none;}
ul li {list-style: none;}
img {vertical-align: top;}

h1, h2, h3, h4, h5, h6 {
	font-size: inherit;
	font-weight: 400;
}
body {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: #EAEBED;
	font-family: 'Nunito', sans-serif;
	font-weight: 400;
	color: #EAEBED;
}



